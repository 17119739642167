.about-content{
  margin: 0 auto;
  max-width: 750px;
}
.project-content{
  background: white;
  padding: var(--space-ml);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.toc {
  display: none;
  z-index: 10;
  line-height: 2em;
}

.toc ul {
  list-style-type: none;
}

.toc li {
  margin-left: 1em;
}

.project-title{
  font-size: 20pt;
  font-family: myCabinBold, sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

@media(min-width: 768px) {
  .project-content{
    padding: var(--space-l);
  }
  .project-title {
    margin-bottom: 40px;
  }
}

@media(min-width: 1200px) {
  .toc {
    display: block;
    width: 14%;
    position: fixed;
    left: 81%;
  }
}

.gallery{
  margin-top: 20px;
  width: 100%;
}

.gallery-img{
  margin: 22px auto;
}

.gallery-img>img{
  margin: 0;
  max-width: 100%;
  /* box-shadow: 0px 2px 6px #202020; */
}

.img-halfl{
  display: inline-block;
  width: 49%;
  margin: 11px 0;
  margin-right: 1%;
}

.img-halfr{
  display: inline-block;
  width: 49%;
  margin: 11px 0;
  margin-left: 1%;
}

#attach-box, #attach-box>*{
  width: 100%;
}

#attach-box>a:hover{
  cursor: pointer;
  color: var(--accent-color);
  transition: color 100ms ease-in;
}

#text-box{
  margin: 40px 0;
}

.back{
  font-family: myCabinBold, sans-serif;
  position: fixed;
  top: 6px;
  left: 10px;
  padding: 2px 6px;
  background-color: white;
  border-radius: 2px;
}

@media(min-width: 768px) {
  .back{
    background-color: transparent;
  }
}