.mdcontainer {
  position: relative;
}
.mdcontainer>p, h1, h2, h3, h4, h5, h6{
  margin: 0.75em 0;
}

.mdcontainer strong, h1, h2, h3, h4, h5{
  font-family: "myCabinBold", var(--base-font-family);
}

.mdcontainer h6{
  font-size: 10pt;
}

.mdcontainer h5{
  font-size: 14pt;
}

.mdcontainer h4{
  font-size: 16pt;
}

.mdcontainer h3{
  margin-top: 1.25em;
  font-size: 18pt;
}

.mdcontainer h2{
  margin-top: 1.5em;
  font-size: 22pt;
}

.mdcontainer h1{
  font-size: 30pt;
}

.mdcontainer a{
  color: var(--accent-color);
}

.mdcontainer a:visited {
  color: var(--accent-color);
}

.mdcontainer summary {
  color: var(--accent-color);
}

.mdcontainer summary:hover {
  cursor: pointer;
}

.mdcontainer>ol li, .mdcontainer>ul li{
  margin-left: 2em;
}
.mdcontainer img{
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.mdcontainer iframe{
  width: 100%;
}

.mdcontainer code {
  background: #efefef;
}

.mdcontainer blockquote {
  padding: var(--space-s);
  background: #fff9ec;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.mdToc ul{
  margin-left: 2em;
}