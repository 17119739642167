html{
	background-color: var(--background-color);
	font-family: var(--base-font-family);
	font-size: var(--base-font-size);
	line-height: var(--base-line-height);
	color: var(--black);
}

*::selection {
  background: var(--selection-color); /* WebKit/Blink Browsers */
}

.clickable:hover {
  cursor: pointer;
  color: var(--accent-color);
  transition: color 100ms ease-in;
}

.content-all {
  margin: var(--space-l) auto 0;
  padding: 0 var(--space-s);
  max-width: 540px;
}

.mainColumn{
	margin-top: var(--space-s);
	padding-top: var(--space-ms);
	border-top: 1px solid var(--accent-color);
}

.sideColumn{
	padding-bottom: var(--space-s);
}

.avatar{
	width: 120px;
  margin: 0 auto var(--space-ms);
}


/* from old style file */

.p5container>div>canvas{
  z-index:-10;
  position: fixed;
  top: 0;
  left: 0;
}

#content-left{
  padding-bottom: 15px;
}

#content-left>#avatar{
  text-align: center;
}


#avatar{
  width: 120px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

#name{
  font-size: 26pt;
  font-family: myCabinBold, sans-serif;
  margin-bottom: 16px;
  text-align: center;
}

#intro{
  margin-top: 0;
  margin-bottom: 20px;
}

.social{
  margin: 0 6px 10px;
}

#social{
  margin-bottom: 20px;
}

.half{
  display: inline-block;
  width: 50%;
}

.right{
  text-align: right;
}
/*
.element{
  position: relative;
}
*/

.snippet-container{
  max-width: 100%;
  margin: 0 auto;
}

.snippet{
  position: relative;
  background: white;
  display: block;
  margin: var(--space-m) auto;
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  transition: 0.1s;
}

.snippet:hover{
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: 0.15s;
}

.snippet>img{
  background: white;
  width: 100%;
}

.caption{
  padding: var(--space-s) var(--space-ms);
}

.caption-title{
  top: var(--space-s);
  right: var(--space-s);
  color: var(--black);
  font-size: 15pt;
  font-family: myCabinBold, sans-serif;
  margin-bottom: 0.15em;
}

.caption-time{
  font-size: var(--small-font-size);
  font-family: var(--small-font-family);
  color: var(--grey-color);
  margin-bottom: var(--space-s);
}

.caption-content{
  letter-spacing: 0.02em;
  top: 48px;
  right: var(--space-s);
  color: var(--black);
}

.caption-content>*>p {
  margin-bottom: 0.5em;
}

/*
ul {
  list-style: none;
  padding-left: 0.75em;
}

ul>li{
  text-indent: -1.2em;
  margin-bottom: 0.25em;
}

ul>li::before{
  content: '-';
  margin-left: 0.35em;
  margin-right: 0.45em;
}
 */

.footer{
  margin-top: 80px;
  margin-bottom: 10px;
  text-align: center;
  font-size: var(--small-font-size);
  font-family: var(--small-font-family);
  color: var(--grey-color);
}

.border{
  border: 1px solid white;
}

@media(min-width: 768px) {
  .content-all{
    width: 750px;
    padding: 0;
    margin: 80px auto;
    max-width: 100%;
  }
  #content-left{
    position: fixed;
    width: 250px;
    padding-bottom: 0;
    padding-right: 60px;
  }
}

@media(min-width: 1200px) {
  .content-all {
    width: 1050px;
  }
  #content-left{
    width: 350px;
  }
  .snippet{
    margin: 0 auto 40px;
    min-height: 180px;
  }
  .snippet>img {
    position: absolute;
    width: 270px;
    height: 180px;
    top: -10px;
    right: 24px;
    padding: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  .caption {
    padding: var(--space-m) 32px;
    max-width: 406px;
  }
}

#intro>*{
  text-align: left;
}