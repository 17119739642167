@charset "utf-8";
@font-face {
  font-family: myCabin; 
  src: url(https://miyehn.me/resource/cabin/Cabin-Regular.otf);
}
@font-face {
	font-family: myCabinBold;
	src: url(https://miyehn.me/resource/cabin/Cabin-SemiBold.otf)
}
@font-face {
	font-family: myLato;
	src: url(https://miyehn.me/resource/Lato/Lato-Regular.ttf)
}
@font-face {
	font-family: myNunitoSans;
	src: url(https://miyehn.me/resource/Nunito_Sans/NunitoSans-SemiBold.ttf)
}

:root{
	/* Font families */
	--chinese-font-family: "Microsoft YaHei UI", "SimHei";
	--fallback-font-family: Helvetica, Arial, var(--chinese-font-family), "Hiragino Sans GB",
	"Hiragino Sans GB W3", "Microsoft JhengHei", "WenQuanYi Micro Hei", "Microsoft YaHei", sans-serif;
	--base-font-family: "myNunitoSans", var(--fallback-font-family);
	--code-font-family: "inconsolata", Consolas, monospace, sans-serif;
	--small-font-family: "myNunitoSans", var(--base-font-family);

	/* Font configuration */
	--base-font-size:   11pt;
	--small-font-size:  9.5pt;
	--big-font-size: 14pt;
	--base-line-height: 1.5em;

	/* Space unit */
	--space-s: 12px;
	--space-ms:18px;
	--space-m: 24px;
	--space-ml:30px;
	--space-l: 84px;

	/* Material color scheme */
	--material-red: #f44336;
	--material-pink: #E91E63;
	--material-purple: #9c27b0;
	--material-deep-purple: #673ab7;
	--material-indigo: #3f51b5;
	--material-blue: #2196f3;
	--material-teal: #009688;
	--material-green: #4caf50;
	--material-light-green: #8bc34a;
	--material-orange: #ff9800;
	--material-deep-orange: #ff5722;
	--material-brown: #795548;
	--material-blue-grey: #607d8b;
	--material-grey: #9e9e9e;
	--material-yellow: #FFEB3B;

	/* Social Networks Brand color */
	--github-color:     #467cc2;
	--twitter-color:    #55acee;
	--facebook-color:   #3765a3;
	--google-color:     #db4437;
	--pinterest-color:  #bc1725;
	--dribbble-color:   #ed699c;
	--weibo-color:      #f8712a;
	--tumblr-color:     #35465c;
	--instagram-color:  #3f729b;
	--linkedin-color:   #0077B5;
	--behance-color:    #1769FF;
	--tixora-color:     #33475A;

	/* Colors */
	--dark: #202020;
	--black: #404040;
	--white: #f5f5f5;
	--background-color: #F8F7F4;
	--brand-color:      #7EA634;
	--accent-color:     var(--material-green);
	--selection-color:  #DCF1C6;
	--grey-color:       #b3b3b3;

	/* Responsive limits */
	--min-sm:        600px;
	--min-md:        992px;
	--min-lg:       1200px;
}

*{
	margin: 0; padding: 0; 
	border: none;
	background: transparent;
}

*:focus {
    outline: none;
}

img{
	display: block;
}

a{
	text-decoration: none;
	color: var(--black);
}

a:visited{
	color: var(--black);
	transition: color 100ms ease-in;
}

@import './normalize.css';
@import './grid12.css';
@import './mdstyle.css';
@import './layout-home.css';
@import './layout-project.css';
